<template>
  <div class="page">
    <div class="banner">
      <img src="../../static/about/banner.png" class="banner-img" />
    </div>
    <div class="menus">
      <div class="menu" @click="change(0)">
        <div class="icon">
          <img
            class="img"
            src="../../static/about/icon-nav1-press.png"
            v-if="active == 0"
          />
          <img class="img" src="../../static/about/icon-nav1.png" v-else />
        </div>
        <div class="text">公司简介</div>
      </div>
      <div class="menu" @click="change(1)">
        <div class="icon">
          <img
            class="img"
            src="../../static/about/icon-nav2-press.png"
            v-if="active == 1"
          />
          <img class="img" src="../../static/about/icon-nav2.png" v-else />
        </div>
        <div class="text">企业文化</div>
      </div>
      <div class="menu" @click="change(2)">
        <div class="icon">
          <img
            class="img"
            src="../../static/about/icon-nav3-press.png"
            v-if="active == 2"
          />
          <img class="img" src="../../static/about/icon-nav3.png" v-else />
        </div>
        <div class="text">资质荣誉</div>
      </div>
      <div class="menu" @click="change(3)">
        <div class="icon">
          <img
            class="img"
            src="../../static/about/icon-nav4-press.png"
            v-if="active == 3"
          />
          <img class="img" src="../../static/about/icon-nav4.png" v-else />
        </div>
        <div class="text">发展历程</div>
      </div>
      <div class="menu" @click="change(4)">
        <div class="icon">
          <img
            class="img"
            src="../../static/about/icon-nav5-press.png"
            v-if="active == 4"
          />
          <img class="img" src="../../static/about/icon-nav5.png" v-else />
        </div>
        <div class="text">联系我们</div>
      </div>
    </div>
    <div class="content">
      <div v-show="active == 0">
        <Info @to="change"></Info>
      </div>
      <div v-show="active == 1">
        <Culture></Culture>
      </div>
      <div v-show="active == 2">
        <Honour></Honour>
      </div>
      <div v-show="active == 3">
        <Course></Course>
      </div>
      <div v-show="active == 4">
        <Contact></Contact>
      </div>
    </div>
  </div>
</template>
<script>
import Info from "../abouts/info.vue";
import Contact from "../abouts/contact.vue";
import Course from "../abouts/course.vue";
import Culture from "../abouts/culture.vue";
import Honour from "../abouts/honour.vue";
export default {
  components: {
    Info,
    Contact,
    Course,
    Culture,
    Honour,
  },
  data() {
    return {
      menus: [],
      active: 0,
    };
  },
  mounted() {
    this.$EventBus.$on("scrollTo", () => {
      this.active = 0;
      setTimeout(() => {
        window.scrollTo(0, 3600);
      }, 500);
    });
  },
  methods: {
    change(index) {
      this.active = index;
    },
  },
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.banner {
  width: 100%;
  .banner-img {
    width: 100%;
    object-fit: contain;
    height: auto;
  }
}
.menus {
  width: 1200px;
  height: 134px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  background: #fff;
  margin-top: -100px;
  display: flex;
  justify-content: space-around;
  .menu {
    width: 120px;
    text-align: center;
    height: 134px;
    padding: 17px 0;
    .icon {
      height: 64px;
      line-height: 64px;
      .img {
        width: 27px;
        height: auto;
      }
    }
    .text {
      font-size: 18px;
      height: 36px;
      line-height: 36px;
    }
  }
}
.content {
  width: 1200px;
  margin: 30px 0;
}
</style>
