<template>
  <div>
    <div class="title">美蜂介绍</div>
    <div class="driver"></div>
    <p class="space">
      美蜂医疗成立于2020年，总部位于中国杭州, 由国资委与国有资产监管委员会联合控股。
      美蜂专注于打造医生与用户之间零距离沟通的一站式医美消费平台。
      集中整合优质医疗整形专家资源，建立信息全面的医生数据库，搭建全链条一站式管家服务体系。
      并以五大运营中心为大区市场布点，对医疗整形医院提供体系化管理输出，再以控股经营模式形成品牌连锁布局，
      由运营中心管理发展所在区域的市场。最终由点成面，覆盖全网,服务客户、服务供应商，实现全国互通。<br />
      美蜂秉承全方位服务共享、专业优质医生共享的理念，
      依托美蜂医疗服务与资源优势实现全国统一价格体系，致力为用户提供最领先、时尚、安全、
      可靠的医疗美容服务，为全行业提供一个安全、健康、专业、规范的医美消费市场。
    </p>
    <div class="images">
      <img
        src="../../static/about/about-us_img1.png"
        style="width: 418px; height: 443px"
      />
      <div style="margin-left: 8px; display: flex; flex-wrap: wrap">
        <img
          src="../../static/about/about-us_img2.png"
          style="width: 334px; height: 217px; margin-right: 7px"
        />
        <img
          src="../../static/about/about-us_img3.png"
          style="width: 433px; height: 217px"
        />
        <img
          src="../../static/about/about-us_img4.png"
          style="width: 268px; height: 217px; margin-right: 7px; margin-top: 9px"
        />
        <img
          src="../../static/about/about-us_img5.png"
          style="width: 499px; height: 217px; margin-top: 9px"
        />
      </div>
    </div>
    <div class="video" style="width: 1200px">
      <video
        style="width: 1200px; margin: 20px 0"
        src="../../static/home/banner.mp4"
        controls="controls"
        poster="../../static/home/poster.png"
      ></video>
    </div>
    <div class="news">
      <div class="ul">
        <div class="list-card">
          <img class="card-images" src="../../static/home/home_img_doctor.png" />
        </div>
        <div class="list-card" style="padding: 30px">
          <div style="list-top"></div>
          <div class="list-title" style="">好医生</div>
          <p class="span">
            严选1200+名合规合法机构医生，严苛标准把关准入门槛严防安全死角<br />
            所有入驻机构均经过6大项、26条标准严格审核合作医生均具备《医生执业证》和《医生资格证》，外籍医师必须具备《外国医师短期行医许可证》。
          </p>
        </div>
        <div class="list-card">
          <img class="card-images" src="../../static/home/home_img_service.png" />
        </div>
        <div class="list-card" style="padding: 30px">
          <div style="list-top"></div>
          <div class="list-title" style="">好机构</div>
          <p class="span">
            严选2000+合规合法机构，严苛标准把关准入门槛<br />
            所有入驻机构均经过6大项、26条标准严格审核 近8成5A机构在美蜂<br />
            年入驻审核率不超过10% 最早上线麻醉准入机制的平台
          </p>
        </div>
        <div class="list-card">
          <img class="card-images" src="../../static/home/home_img_mechanism.png" />
        </div>
        <div class="list-card" style="padding: 30px">
          <div style="list-top"></div>
          <div class="list-title" style="">好服务</div>
          <p class="span">
            800余名严选咨询师，提供1对1专业医美咨询服务。<br />
            通过术前咨询、术后恢复、机构面诊、到院进行项目执行，为顾客提供全流程服务。<br />
            提供高端会员尊享服务。
          </p>
        </div>
      </div>
    </div>

    <div class="map">
      <div class="map-left">
        <div class="title">服务范围</div>
        <div class="driver"></div>
        <div class="desc">
          覆盖北京、上海、杭州、南京 深圳等全国30个城市，拥有在 线名医1200名，服务100000
          用户。
        </div>
        <div class="tags">
          <img src="../../static/home/home_label_city.png" class="tag" />
          <img src="../../static/home/home_label_service.png" class="tag" />
          <img src="../../static/home/home_label_user.png" class="tag" />
        </div>
      </div>
      <div class="map-right">
        <img src="../../static/home/home_img_map.png" class="map-img" />
      </div>
    </div>
    <h2 style="text-align: center; margin: 10px 0; font-weight: bold">招商合作</h2>
    <div class="form">
      <div class="area">
        <b-row class="my-1">
          <b-col sm="2">
            <label>您的姓名 <code style="color: red">*</code> :</label>
          </b-col>
          <b-col sm="10">
            <b-form-input v-model="form.name" type="text" placeholder="请输入您的姓名"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="my-1">
          <b-col sm="2">
            <label>手机号码 <code style="color: red">*</code> :</label>
          </b-col>
          <b-col sm="10">
            <b-form-input v-model="form.mobile" placeholder="请输入您的手机号码"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="2">
            <label>留言 :</label>
          </b-col>
          <b-col sm="10">
            <b-form-textarea
              rows="3"
              maxlength="50"
              placeholder="请输入留言 0/50"
              v-model="form.message"
            ></b-form-textarea>
          </b-col>
        </b-row>
        <b-row class="my-row">
          <b-button style="width: 120px" @click="submit" :disabled="loading">提交</b-button>
        </b-row>
      </div>
    </div>
  </div>
</template>
<script>
const testReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
export default {
  data() {
    return {
      form: {
        name: "",
        mobile: "",
        message: ""
      },
      loading: false
    }
  },
  methods: {
    submit() {
       if(!this.form.name) return this.$store.commit("showToast", "请输入姓名");
       if (!testReg.test(this.form.mobile)) return this.$store.commit("showToast", "请输入正确的手机号码格式");
      this.loading = true
      this.$http.addmessage(this.form).then(res => {
        this.loading = false
        if(res.code == 1) {
          this.$store.commit("showToast", "提交成功")
          setTimeout(() => {
            for(let i in this.form) {
              this.form[i] = ""
            }
            window.scrollTo(0,0)
            this.$emit("to", 4)
          }, 1000)
        }else {
          this.$store.commit("showToast", res.msg)
        }
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style scoped lang="scss">
.page {
  width: 100%;
}
.title {
  font-size: 30px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  width: 100%;
}
.driver {
  width: 80px;
  height: 2px;
  background: #fd9e84;
  margin-left: 560px;
}
.space {
  margin: 20px 0;
  line-height: 28px;
  font-size: 14px;
  color: #999;
}
.images {
  width: 1200px;
  display: flex;
}

.form {
  width: 1200px;
  margin: 20px 0;
  background: url("../../static/about/about-info-bg.jpg") no-repeat;
  height: 500px;
  background-size: 1200px;
  display: flex;
  justify-content: center;
  .area {
    width: 900px;
    height: 400px;
    background: rgba(255, 255, 255, 0.6);
    margin-top: 50px;
    padding: 40px 25px;
  }
  .my-1 {
    height: 50px;
  }
  .my-row {
    display: flex;
    justify-content: center;
    margin-top: 40px
  }
}
.news {
  width: 100%;
  background: #f6f6f9;
  //   height: 900px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  .ul {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .list-card {
      width: 400px;
      .card-images {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
      .list-top {
        width: 35px;
        height: 6px;
        background: #fd9852;
      }
      .list-title {
        color: #333;
        font-size: 32px;
        font-weight: bold;
        line-height: 64px;
      }
      .span {
        font-size: 22px;
        color: #999;
        width: 320px;
      }
    }
  }
}

.map {
  width: 1200px;
  display: flex;
  padding: 30px 0;
  .map-left {
    .title {
      font-size: 32px;
      font-weight: bold;
      line-height: 64px;
      height: 64px;
      margin: 20px 0;
    }
    .driver {
      width: 240px;
      height: 1px;
      background: #707070;
      margin: 5px 0;
    }
    .desc {
      width: 360px;
      font-size: 26px;
      line-height: 46px;
      margin: 20px 0;
    }
  }
  .tags {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .tag {
      width: 139px;
      height: 70px;
      margin-right: 20px;
    }
  }
  .map-img {
    width: 700px;
    height: auto;
  }
}
</style>
