<template>
  <div class="page">
    <div>
      <div class="title">发展历程</div>
      <div class="driver" style="margin-left: 20px"></div>
    </div>
    <div style="width: 1200px; height: 60px; padding: 10px 0; margin: 10px 0">
      <div class="tab-line">
        <div
          class="tab-line-item"
          :class="current == 0 ? 'tab-line-item-active' : ''"
          @click="current = 0"
        >
          2020年
        </div>
        <div
          class="tab-line-item"
          :class="current == 1 ? 'tab-line-item-active' : ''"
          @click="current = 1"
        >
          2021年
        </div>
        <div
          class="tab-line-item"
          :class="current == 2 ? 'tab-line-item-active' : ''"
          @click="current = 2"
        >
          2022年
        </div>
      </div>
    </div>
    <div class="timeline-area">
      <div class="line-area" v-for="(item, index) in list" :key="index">
        <div :class="index % 2 == 0 ? 'right' : 'left'">
          <div style="width: 580px">
            <div class="linearea-time">{{ item.time }}</div>
            <div class="linearea-title">
              {{ item.title }}
              <span class="linearea-desc">{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="order-area">
      <div class="order-item" v-for="(order, index) in list" :key="index">
        <div
          class="order-circle"
          :class="index == 0 || index + 1 == list.length ? '' : 'order-transform'"
        >
          <b-icon icon="circle"></b-icon>
        </div>
      </div>
    </div>
    <div v-if="list.length == 0">暂无数据</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      list1: [
        {
          time: "2020-10",
          title: "立项: 创始团队人员到位",
          desc: "Founding team personnel in place",
        },
        {
          time: "2020-11",
          title: "天使轮2000万融资到账 美蜂公司正式成立",
          desc: "Formally established",
        },
      ],
      list2: [
        {
          time: "2021-01",
          title: "美蜂项目研发正式开始",
          desc: "Meifeng app project R&D officially started",
        },
        {
          time: "2021-02",
          title: "线下渠道预热 蓄客开始",
          desc: "Offline channel preheating start storing customers",
        },
        {
          time: "2021-03",
          title: "100+名医入驻项目 并购供应链公司",
          desc: "100+Famous doctors settled in the project",
        },
        {
          time: "2021-04",
          title: "APP beta 1.0版上线",
          desc: "App beta version 1.0 goes online",
        },
        {
          time: "2021-05",
          title: "全产业链布局&收购徐州医院",
          desc: "Whole industry chain layout&  Acquisition of Xuzhou hospital",
        },
        {
          time: "2021-06",
          title: "集合项目子公司合并成立美菲尔集团公司",
          desc: "Consolidation of subsidiaries of  collective projects",
        },
        {
          time: "2021-07",
          title: "APP beta 1.0版上线",
          desc: "App beta version 1.0 goes online",
        },
        {
          time: "2021-08",
          title: "APP beta 1.0版上线",
          desc: "App beta version 1.0 goes online",
        },
        {
          time: "2021-09",
          title: "APP beta 1.0版上线",
          desc: "App beta version 1.0 goes online",
        },
        {
          time: "2021-10",
          title: "APP beta 1.0版上线",
          desc: "App beta version 1.0 goes online",
        },
      ],
      list3: [],
      current: 0,
    };
  },
  mounted() {
    this.list = this.list1;
  },
  watch: {
    current: function (val) {
      switch (val) {
        case 0:
          this.list = this.list1;
          break;
        case 1:
          this.list = this.list2;
          break;
        case 2:
          this.list = this.list3;
          break;
      }
    },
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.page {
  width: 100%;
 
}
 .title {
    font-size: 30px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    width: 100%;
  }
  .driver {
    width: 80px;
    height: 2px;
    background: #fd9e84;
    margin: 0 auto;
  }
.timeline-area {
  width: 1200px;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  .right {
    float: left;
    width: 600px;
    display: flex;
    text-align: right;
    padding-right: 30px;
  }
  .left {
    float: right;
    width: 600px;
    display: flex;
    padding-left: 30px;
  }

  .line-area {
    width: 1200px;
    margin: 10px 0;
    .linearea-time {
      font-size: 20px;
      font-weight: bold;
    }
    .linearea-title {
      font-size: 16px;
      color: #fd9852;
      height: 32px;
      line-height: 32px;
    }
    .linearea-desc {
      font-size: 14px;
      color: #999;
      margin: 0 10px;
    }
  }
}

.order-area {
  position: absolute;
  margin-top: 162px;
  width: 100px;
  .order-item {
    height: 82px;
    display: flex;
    justify-content: center;
  }
  .order-transform {
    border: none !important;
  }
  .order-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #999;
    color: #999;
    line-height: 30px;
    text-align: center;
    margin-top: 15px;
  }
}
.tab-line {
  height: 40px;
  width: 362px;
  border: 1px solid #e6e7e9;
  display: flex;
  line-height: 40px;
  text-align: center;
  .tab-line-item {
    width: 120px;
  }
  .tab-line-item:hover {
    cursor: pointer;
  }
  .tab-line-item-active {
    background: #fd9852;
    color: #fff;
  }
}
</style>
